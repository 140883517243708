import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectVigilante (Vue, filter, search, sorter, page, idservicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
    if (idservicio) {
      apiFilter.addExact('idpuesto_servicio_servicio', idservicio)
    }
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('nombre_vigilante', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.nombreVigilante.value) {
      apiFilter.addILike(filter.nombreVigilante.field, filter.nombreVigilante.value)
    }
    const resp = await Vue.$api.call('vigilante.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectVigilanteRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idvigilante', pks)
    const resp = await Vue.$api.call('vigilante.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
